import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

const renderImage = file => {
  return file ? <GatsbyImage image={file.node.childImageSharp.gatsbyImageData} /> : <></>
}

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { extension: { regex: "/jpeg|jpg/" } }) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED placeholder: BLURRED)
                }
              }
            }
          }
        }
      `}
      render={({ images }) =>
        renderImage(
          images.edges.find(image => image.node.relativePath === props.image)
        )
      }
    />
  )
}
