import React from 'react'
import PropTypes from 'prop-types'

const Header = props => (
  <header id="header" style={props.hasTimeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <h1>Killian Nestor</h1>
        <p>Full Stack Web Developer</p>
        <p>Mayo, Ireland</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            onClick={() => {
              props.onOpenArticle('intro')
            }}
            style={{ cursor: 'pointer' }}
          >
            Intro
          </a>
        </li>
        <li>
          <a
            onClick={() => {
              props.onOpenArticle('about me')
            }}
            style={{ cursor: 'pointer' }}
          >
            About
          </a>
        </li>
        <li>
          <a
            onClick={() => {
              props.onOpenArticle('reading')
            }}
            style={{ cursor: 'pointer' }}
          >
            Reading
          </a>
        </li>
        <li>
          <a
            onClick={() => {
              props.onOpenArticle('contact')
            }}
            style={{ cursor: 'pointer' }}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  hasTimeout: PropTypes.bool.isRequired,
}

export default Header
