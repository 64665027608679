import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import ArticleList from './ArticleList'
import ContactForm from './ContactForm'
import useOutsideClick from '../shared/useOutsideClick'

const Main = props => {
  const ref = useRef()

  useOutsideClick(ref, props.onCloseArticle)

  return (
    <div
      ref={ref}
      id="main"
      style={props.hasTimeout ? { display: 'flex' } : { display: 'none' }}
    >
      <ArticleList
        article={props.article}
        hasArticleTimeout={props.hasArticleTimeout}
        onCloseArticle={props.onCloseArticle}
      />
      <ContactForm
        article={props.article}
        hasArticleTimeout={props.hasArticleTimeout}
        onCloseArticle={props.onCloseArticle}
      />
    </div>
  )
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  hasArticleTimeout: PropTypes.bool.isRequired,
  onCloseArticle: PropTypes.func,
  hasTimeout: PropTypes.bool.isRequired,
}

export default Main
