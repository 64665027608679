import React from 'react'
import PropTypes from 'prop-types'

const Footer = props => (
  <footer id="footer" style={props.hasTimeout ? { display: 'none' } : {}}>
    <p className="copyright">
      Built with <a href="https://www.gatsbyjs.org/">Gatsby.js</a>
    </p>
  </footer>
)

Footer.propTypes = {
  hasTimeout: PropTypes.bool.isRequired,
}

export default Footer
