import React from 'react'
import ArticleImage from './ArticleImage'

export default props => (
  <article
    id={props.id}
    className={`${props.article === props.title ? 'active' : ''} ${
      props.hasArticleTimeout ? 'timeout' : ''
    }`}
    style={{ display: 'none' }}
  >
    <h2 className="major">{props.title}</h2>
    <span className="image main">
      <ArticleImage image={props.image} alt="" />
    </span>
    <div dangerouslySetInnerHTML={{ __html: props.html }} />
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle()
      }}
    ></div>
  </article>
)
