import React from 'react'

export default props => (
  <article
    id="contact"
    className={`${props.article === 'contact' ? 'active' : ''} ${
      props.hasArticleTimeout ? 'timeout' : ''
    }`}
    style={{ display: 'none' }}
  >
    <h2 className="major">Contact</h2>
    <form
      method="post"
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="gotcha-field"
    >
      <div>
        <input type="text" name="gotcha-field" style={{ display: 'none' }} />
      </div>
      <input type="hidden" name="form-name" value="contact" />
      <div className="field half first">
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" />
      </div>
      <div className="field half">
        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" />
      </div>
      <div className="field">
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" rows="4"></textarea>
      </div>
      <ul className="actions">
        <li>
          <input type="submit" value="Send Message" className="special" />
        </li>
        <li>
          <input type="reset" value="Reset" />
        </li>
      </ul>
    </form>
    <ul className="icons">
      <li>
        <a
          href="https://www.linkedin.com/in/killiannestor"
          className="icon fa-linkedin"
        >
          <span className="label">LinkedIn</span>
        </a>
      </li>
      <li>
        <a href="https://github.com/victari0n" className="icon fa-github">
          <span className="label">GitHub</span>
        </a>
      </li>
    </ul>
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle()
      }}
    ></div>
  </article>
)
