import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'

const IndexPage = props => {
  const [isArticleVisible, setIsArticleVisible] = useState(false)
  const [hasTimeout, setHasTimeout] = useState(false)
  const [hasArticleTimeout, setHasArticleTimeout] = useState(false)
  const [article, setArticle] = useState('')
  const [loading, setLoading] = useState('is-loading')

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading('')
    }, 100)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isArticleVisible])

  const handleOpenArticle = article => {
    setIsArticleVisible(true)
    setArticle(article)

    setTimeout(() => {
      setHasTimeout(!hasTimeout)
    }, 325)

    setTimeout(() => {
      setHasArticleTimeout(!hasArticleTimeout)
    }, 350)
  }

  const handleCloseArticle = () => {
    setHasArticleTimeout(!hasArticleTimeout)

    setTimeout(() => {
      setHasTimeout(!hasTimeout)
    }, 325)

    setTimeout(() => {
      setIsArticleVisible(false)
      setArticle('')
    }, 350)
  }

  return (
    <Layout location={props.location}>
      <div
        className={`body ${loading} ${
          isArticleVisible ? 'is-article-visible' : ''
        }`}
      >
        <div id="wrapper">
          <Header onOpenArticle={handleOpenArticle} hasTimeout={hasTimeout} />
          {isArticleVisible && (
            <Main
              hasTimeout={hasTimeout}
              hasArticleTimeout={hasArticleTimeout}
              article={article}
              onCloseArticle={handleCloseArticle}
            />
          )}
          <Footer hasTimeout={hasTimeout} />
        </div>
        <div id="bg"></div>
      </div>
    </Layout>
  )
}

export default IndexPage
