import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Article from './Article'

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          edges {
            node {
              id
              frontmatter {
                title
                image
              }
              html
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Article
            key={node.id}
            id={node.id}
            title={node.frontmatter.title}
            html={node.html}
            image={node.frontmatter.image}
            hasArticleTimeout={props.hasArticleTimeout}
            article={props.article}
            onCloseArticle={props.onCloseArticle}
          />
        ))}
      </>
    )}
  />
)
